import React from "react";
import styled from "styled-components";

import { getBattleRatingData } from "libs/fight";
import { BattleRatings } from "types";
import Tooltip, { TooltipDesc, TooltipName } from "./Tooltip";

interface BattleRatingProps {
  rating: BattleRatings;
  iconLeft?: boolean;
}

interface BattleRatingWrapperProps {
  iconLeft: boolean;
}

interface BattleRatingNameProps {
  color: string;
}

const BattleRatingWrapper = styled.div<BattleRatingWrapperProps>`
  display: flex;
  gap: 5px;
  text-align: right;
  align-items: center;
  user-select: none;

  ${(props) => props.iconLeft && `flex-direction: row-reverse;`}
`;

const BattleRatingImage = styled.img`
  height: 30px;
`;

const BattleRatingName = styled.div<BattleRatingNameProps>`
  text-transform: uppercase;
  font-weight: 600;
  color: ${(props) => props.color};
`;

export default function BattleRatingSmall({
  rating,
  iconLeft = false,
}: BattleRatingProps) {
  const battleRatingData = getBattleRatingData(rating);

  return (
    <Tooltip
      color={battleRatingData.color}
      content={
        <>
          <TooltipName>Battle Rating: {battleRatingData.name}</TooltipName>
          <TooltipDesc>
            {`You are rewarded this item if you achieve a ${battleRatingData.name} 
        battle rating in a fight. You also get all the other items below this
        rating.`}
          </TooltipDesc>
          <TooltipDesc>
            {`${battleRatingData.name} battle rating means: ${battleRatingData.description}`}
          </TooltipDesc>
        </>
      }
    >
      <BattleRatingWrapper iconLeft={iconLeft}>
        <BattleRatingName color={battleRatingData.color}>
          {battleRatingData.name}
        </BattleRatingName>
        <BattleRatingImage src={battleRatingData.image} />
      </BattleRatingWrapper>
    </Tooltip>
  );
}
