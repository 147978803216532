import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { getCharacter } from "redux/selectors";
import {
  CharacterBaseStats,
  CharacterDerivedStats,
  StatRangeData,
  StatRanges,
} from "types";
import { getStatRangeData } from "libs/stats";

import Tooltip, {
  RangesIcon,
  TooltipDesc,
  TooltipInfo,
  TooltipName,
  TooltipRanges,
  TooltipValues,
  ValuesIcon,
} from "components/base/Tooltip";
import {
  DERIVED_STATS,
  DERIVED_STATS_GROUPS,
  DerivedStatsGroupModel,
} from "data/derivedStats";
import { CategoryGroup, CategoryName, CategorySeparator } from "./DataScreen";

interface DerivedStatsProps {
  highlightStat: keyof CharacterBaseStats | null;
}

interface IconProps {
  image: string;
}

interface DerivedStatProps {
  slug: keyof CharacterDerivedStats;
  value: number;
  baseValue: number;
  upgradeValue: number;
  highlightStat: keyof CharacterBaseStats | null;
}

interface DerivedStatWrapperProps {
  $isDim: boolean;
  $color: string;
}

interface StatRangeProps {
  slug: keyof StatRanges;
  statRange: StatRangeData;
  baseRange: StatRangeData;
  upgradesRange: StatRangeData;
  highlightStat: keyof CharacterBaseStats | null;
}

interface StatRangeWrapperProps {
  $isDim: boolean;
  $color: string;
}

interface StatRangeColorsProps {
  $color: string;
}

const dimMixin = css`
  opacity: 0.2;
`;

const DerivedStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StatList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StatIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StatIcon = styled.div<IconProps>`
  width: 10px;
  height: 10px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: white;
`;

const DerivedStatWrapper = styled.div<DerivedStatWrapperProps>`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  transition: all 100ms ease-out;
  ${(props) => props.$isDim && dimMixin}
  ${(props) => `color: ${props.$color};`}

  ${StatIcon} {
    ${(props) => `background-color: ${props.$color};`}
  }
`;

const TooltipTotalStat = styled.div`
  font-weight: 600;
  filter: brightness(120%) saturate(120%);
  margin-top: 2px;
`;

const TotalStatName = styled.span`
  text-transform: uppercase;
`;

const StatRangeWrapper = styled.div<StatRangeWrapperProps>`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  transition: all 100ms ease-out;
  ${(props) => props.$isDim && dimMixin}

  color: ${(props) => props.$color};

  ${StatIcon},
  ${RangesIcon} {
    background-color: ${(props) => props.$color};
  }
`;

const TooltipStatRangeValues = styled(TooltipRanges)<StatRangeColorsProps>`
  color: ${(props) => props.$color};

  ${StatIcon},
  ${RangesIcon} {
    background-color: ${(props) => props.$color};
  }
`;

const DerivedStatName = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StatValue = styled.div``;

const DerivedStat = ({
  slug,
  value,
  baseValue,
  upgradeValue,
  highlightStat = null,
}: DerivedStatProps) => {
  const statSlug = slug as keyof CharacterDerivedStats;
  const statInfo = DERIVED_STATS[statSlug];

  return (
    <Tooltip
      color={statInfo.color}
      content={
        <>
          <TooltipName>{statInfo.name}</TooltipName>
          <TooltipDesc>{statInfo.description}</TooltipDesc>
          <TooltipInfo>
            <TooltipValues color={statInfo.color}>
              {`Base Value: ${statInfo.formatter(baseValue)}`}
            </TooltipValues>
            <TooltipValues color={statInfo.color}>
              {`Upgrade Bonus: [${statInfo.addFormatter(upgradeValue)}]`}
            </TooltipValues>
            <TooltipTotalStat>
              <TooltipValues color={statInfo.color}>
                <ValuesIcon image={statInfo.image} color={statInfo.color} />
                <div>
                  <TotalStatName>Total Value: </TotalStatName>
                  {statInfo.formatter(value)}
                </div>
              </TooltipValues>
            </TooltipTotalStat>
          </TooltipInfo>
        </>
      }
    >
      <DerivedStatWrapper
        $isDim={!!highlightStat && highlightStat !== statInfo.baseStat}
        $color={statInfo.color}
      >
        <DerivedStatName>
          <StatIconWrapper>
            <StatIcon image={statInfo.image} />
          </StatIconWrapper>
          {statInfo.name}
        </DerivedStatName>
        <StatValue>{statInfo.formatter(value)}</StatValue>
      </DerivedStatWrapper>
    </Tooltip>
  );
};

const StatRange = ({
  slug,
  statRange,
  baseRange,
  upgradesRange,
  highlightStat = null,
}: StatRangeProps) => {
  const statRangeSlug = slug as keyof StatRanges;
  const statRangeInfo = getStatRangeData(statRangeSlug);

  return (
    <Tooltip
      color={statRangeInfo.color}
      content={
        <>
          <TooltipName>{statRangeInfo.name}</TooltipName>
          <TooltipDesc>{statRangeInfo.description}</TooltipDesc>
          <TooltipInfo>
            <TooltipStatRangeValues $color={statRangeInfo.color}>
              {`Base Range: ${statRangeInfo.formatter(
                baseRange.min,
                baseRange.max
              )}`}
            </TooltipStatRangeValues>
            <TooltipStatRangeValues $color={statRangeInfo.color}>
              {`Upgrade Bonus: ${statRangeInfo.addFormatter(
                upgradesRange.min,
                upgradesRange.max
              )}`}
            </TooltipStatRangeValues>
            <TooltipTotalStat>
              <TooltipStatRangeValues $color={statRangeInfo.color}>
                <StatIconWrapper>
                  {statRangeInfo.images.map((image) => (
                    <RangesIcon key={image} image={image} />
                  ))}
                </StatIconWrapper>
                <div>
                  <TotalStatName>Total Range: </TotalStatName>
                  {statRangeInfo.formatter(statRange.min, statRange.max)}
                </div>
              </TooltipStatRangeValues>
            </TooltipTotalStat>
          </TooltipInfo>
        </>
      }
    >
      <StatRangeWrapper
        $isDim={
          !!highlightStat && !statRangeInfo.baseStats.includes(highlightStat)
        }
        $color={statRangeInfo.color}
      >
        <DerivedStatName>
          <StatIconWrapper>
            {statRangeInfo.images.map((image) => (
              <StatIcon key={image} image={image} />
            ))}
          </StatIconWrapper>
          {statRangeInfo.name}
        </DerivedStatName>
        <StatValue>
          {statRangeInfo.formatter(statRange.min, statRange.max)}
        </StatValue>
      </StatRangeWrapper>
    </Tooltip>
  );
};

export default function DerivedStats({ highlightStat }: DerivedStatsProps) {
  const {
    data: { derivedStats, derivedStatRanges },
  } = useSelector(getCharacter);

  return (
    <DerivedStatsWrapper>
      {Object.values(DERIVED_STATS_GROUPS).map((value) => {
        const statGroupData = value as DerivedStatsGroupModel;
        return (
          <CategoryGroup key={statGroupData.name}>
            <CategoryName>
              {statGroupData.name}
              <CategorySeparator />
            </CategoryName>
            <StatList>
              {statGroupData.stats.map((derivedStat) => {
                if (derivedStat.isRange) {
                  const statSlug = derivedStat.slug as keyof StatRanges;
                  return (
                    <StatRange
                      key={statSlug}
                      slug={statSlug}
                      statRange={derivedStatRanges.current.complete[statSlug]}
                      baseRange={derivedStatRanges.current.base[statSlug]}
                      upgradesRange={
                        derivedStatRanges.current.upgrades[statSlug]
                      }
                      highlightStat={highlightStat}
                    />
                  );
                } else {
                  const statSlug =
                    derivedStat.slug as keyof CharacterDerivedStats;
                  return (
                    <DerivedStat
                      key={statSlug}
                      slug={statSlug}
                      value={derivedStats.current.complete[statSlug]}
                      baseValue={derivedStats.current.base[statSlug]}
                      upgradeValue={derivedStats.current.upgrades[statSlug]}
                      highlightStat={highlightStat}
                    />
                  );
                }
              })}
            </StatList>
          </CategoryGroup>
        );
      })}
    </DerivedStatsWrapper>
  );
}
