import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import enhancementsImage from "assets/enhancements.svg";
import LargeWeakenBar from "components/base/LargeWeakenBar";
import Medallions from "components/base/Medallions";
import Tooltip, {
  TooltipDesc,
  TooltipInfo,
  TooltipName,
  TooltipValues,
  ValuesIcon,
} from "components/base/Tooltip";
import { BASE_STATS_INFO } from "data/baseStats";
import { DERIVED_STATS, DerivedStatInfo } from "data/derivedStats";
import {
  getShipData,
  getStatsResetCost,
  MAX_BASE_STAT_VALUE,
} from "libs/stats";
import { buyEnhanceStat, buyResetStats, showMessage } from "redux/actions";
import { getCharacter } from "redux/selectors";
import { CharacterBaseStats, CharacterDerivedStats } from "types";
import { COLORS } from "utils/constants";
import Button from "./Button";
import {
  ButtonCredits,
  ButtonCreditsIcon,
  ScreenButtonWrapper,
  screenItemMixin,
} from "./DataScreen";

interface BaseStatsProps {
  canEnhance?: boolean;
  onSetHighlightStat: (x: keyof CharacterBaseStats) => void;
  onResetHighlightStat: () => void;
}

interface IconProps {
  image: string;
}

interface BaseStatProps {
  enhanceable: boolean;
}

const BaseStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BaseStatsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const EnhancementsIcon = styled.div<IconProps>`
  width: 16px;
  height: 16px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.ENHANCEMENTS};
`;

const EnhancementCostWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.5;
  transition: all 100ms ease-out;
  color: ${COLORS.ENHANCEMENTS};
`;

const EnhancementCost = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const EnhanceText = styled.div`
  display: none;
`;
const CostText = styled.div``;

const enhanceableMixin = css`
  :hover ${EnhancementCostWrapper} {
    opacity: 1;
  }

  :hover ${EnhanceText} {
    display: block;
  }

  :hover ${CostText} {
    display: none;
  }
`;

const BaseStat = styled.div<BaseStatProps>`
  ${screenItemMixin}
  cursor: pointer;
  padding-bottom: 15px;

  ${(props) => !!props.enhanceable && enhanceableMixin}
`;

const BaseStatIcon = styled.div<IconProps>`
  height: 20px;
  width: 20px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

const StatInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatName = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const BarWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export default function BaseStats({
  canEnhance = false,
  onSetHighlightStat,
  onResetHighlightStat,
}: BaseStatsProps) {
  const dispatch = useDispatch();
  const {
    data: { currentBaseStats, weakenedBaseStats, ship, medallions },
  } = useSelector(getCharacter);

  const statsCosts = getShipData(ship).baseStatsCosts;

  // Use spent medallions instead of reset cost to enable/disable reset button, since mechanical engineers reset for free
  const canReset = !!medallions.statsSpent;
  const resetCost = getStatsResetCost(medallions.statsSpent);

  const enhanceStat = (stat: keyof CharacterBaseStats) => {
    if (!!canEnhance) {
      dispatch(buyEnhanceStat({ baseStat: stat }));
    } else {
      dispatch(
        showMessage(`Find the nearest Shipworks to enhance this attribute`)
      );
    }
  };

  return (
    <BaseStatsWrapper>
      <BaseStatsList>
        <Medallions />
        {Object.values(BASE_STATS_INFO).map((baseStatInfo) => {
          const statSlug = baseStatInfo.slug as keyof CharacterBaseStats;
          return (
            <BaseStat
              key={statSlug}
              enhanceable={canEnhance}
              onClick={() => enhanceStat(statSlug)}
              onMouseEnter={() => onSetHighlightStat(statSlug)}
              onMouseLeave={onResetHighlightStat}
            >
              <Tooltip
                color={baseStatInfo.color}
                content={
                  <>
                    <TooltipName>{baseStatInfo.name}</TooltipName>
                    <TooltipDesc>{baseStatInfo.description}</TooltipDesc>
                    <TooltipInfo>
                      Each enhancement adds:
                      {Object.entries(DERIVED_STATS)
                        .filter(([key, value]) => {
                          const statInfo = value as DerivedStatInfo;
                          return statInfo.baseStat === baseStatInfo.slug;
                        })
                        .map(([key, value]) => {
                          const statSlug = key as keyof CharacterDerivedStats;
                          const statInfo = value as DerivedStatInfo;

                          return (
                            <TooltipValues
                              key={statSlug}
                              color={statInfo.color}
                            >
                              <ValuesIcon
                                image={statInfo.image}
                                color={statInfo.color}
                              />
                              {`${statInfo.name}: ${statInfo.addFormatter(
                                statInfo.incrementValue
                              )}`}
                            </TooltipValues>
                          );
                        })}
                    </TooltipInfo>
                  </>
                }
              >
                <StatInfo>
                  <StatName>{baseStatInfo.name}</StatName>
                  <EnhancementCostWrapper>
                    <EnhanceText>Enhance Stat:</EnhanceText>
                    <CostText>Cost:</CostText>
                    <EnhancementCost>
                      <EnhancementsIcon image={enhancementsImage} />
                      {statsCosts[statSlug]}
                    </EnhancementCost>
                  </EnhancementCostWrapper>
                </StatInfo>
                <BarWrapper>
                  <BaseStatIcon
                    image={baseStatInfo.image}
                    color={baseStatInfo.color}
                  />
                  <LargeWeakenBar
                    number={currentBaseStats[statSlug]}
                    weakenNumber={weakenedBaseStats[statSlug]}
                    maxNumber={MAX_BASE_STAT_VALUE}
                    color={baseStatInfo.color}
                    isReversed={false}
                  />
                </BarWrapper>
              </Tooltip>
            </BaseStat>
          );
        })}
      </BaseStatsList>
      {!!canEnhance && (
        <ScreenButtonWrapper>
          <Tooltip
            color={COLORS.ENHANCEMENTS}
            content={
              <>
                <TooltipName>Reset Medallions</TooltipName>
                <TooltipDesc>
                  Reset your core attributes back to their default values and
                  get back your spent medallions. Installed upgrades may be
                  uninstalled automatically if your ship no longer meets the
                  requirements.
                </TooltipDesc>
              </>
            }
          >
            <Button
              $style="normal"
              $size="medium"
              $disabled={!canReset}
              onClick={() => dispatch(buyResetStats({ credits: resetCost }))}
            >
              Reset Medallions
              <ButtonCredits>
                <ButtonCreditsIcon />
                {resetCost}
              </ButtonCredits>
            </Button>
          </Tooltip>
        </ScreenButtonWrapper>
      )}
    </BaseStatsWrapper>
  );
}
