import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import enhancementsImage from "assets/enhancements.svg";
import Button from "components/base/Button";
import {
  ButtonCredits,
  ButtonCreditsIcon,
  CategoryGroup,
  CategoryName,
  CategorySeparator,
  DataWrapper,
  ScreenButtonWrapper,
  screenItemMixin,
  Section,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import Medallions from "components/base/Medallions";
import Skill from "components/base/Skill";
import Tooltip, { TooltipDesc, TooltipName } from "components/base/Tooltip";
import { pilotScreenAnimation } from "components/styles/animations";
import { buyResetSkills, buyTrainSkill, showMessage } from "redux/actions";
import { getCharacter } from "redux/selectors";
import { COLORS } from "utils/constants";
import { getPilotData } from "libs/stats";
import { getSkillsResetCost } from "libs/skill";

interface PilotSkillsProps {
  canTrain: boolean;
}

interface IconProps {
  image: string;
}

interface SkillWrapperProps {
  trainable: boolean;
}

const PilotInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PilotImageWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 1px #43fffa solid;
  border-radius: 5px;
  background-color: #000000;
  background-size: 0.3em 0.3em;
  background-position: center center;
  background-image: linear-gradient(to bottom, #aaaaaa50 1px, transparent 1px);
  animation: ${pilotScreenAnimation} 4s linear infinite;
`;

const PilotImage = styled.img`
  width: 50%;
  display: block;
  mix-blend-mode: color-dodge;
  z-index: 2;
`;

const PilotName = styled.div`
  font-size: 4vw;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  mix-blend-mode: hard-light;
  color: ${COLORS.PILOTS};
  text-align: center;
`;

const PilotDesc = styled.div`
  ${screenItemMixin}
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Skills = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SkillInfo = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

const LockedSkillInfo = styled(SkillInfo)`
  opacity: 0.5;
`;

const TrainText = styled.div`
  display: none;
`;
const CostText = styled.div``;

const trainableMixin = css`
  :hover ${LockedSkillInfo} {
    opacity: 1;
  }

  :hover ${TrainText} {
    display: block;
  }

  :hover ${CostText} {
    display: none;
  }
`;

const SkillWrapper = styled.div<SkillWrapperProps>`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  ${(props) => !!props.trainable && trainableMixin}
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
`;

const Unlocked = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${COLORS.UNLOCKED};
`;

const TrainCostWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${COLORS.ENHANCEMENTS};
`;

const EnhancementsIcon = styled.div<IconProps>`
  width: 16px;
  height: 16px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.ENHANCEMENTS};
`;

const EnhancementCost = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export default function PilotSkills({ canTrain = false }: PilotSkillsProps) {
  const dispatch = useDispatch();
  const {
    data: { pilot, skills, medallions, fighterData: characterFighter },
  } = useSelector(getCharacter);
  const {
    name: pilotName,
    images: pilotImages,
    description: pilotDescription,
  } = getPilotData(pilot);

  const resetCost = getSkillsResetCost(medallions.skillsSpent);
  const canReset = !!resetCost;

  const trainSkill = (skill: string) => {
    if (!!canTrain) {
      dispatch(buyTrainSkill({ skill }));
    } else {
      dispatch(
        showMessage(`Find the nearest Pilot Academy to learn this skill`)
      );
    }
  };

  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Pilot Info</SectionTitle>
        <SectionContentScroll>
          <PilotInfo>
            <PilotImageWrapper>
              <PilotImage src={pilotImages.avatar} />
              <PilotName>{pilotName}</PilotName>
            </PilotImageWrapper>
            <PilotDesc>{pilotDescription}</PilotDesc>
          </PilotInfo>
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Skills List</SectionTitle>
        <SectionContentScroll>
          <Medallions />
          <ListWrapper>
            <CategoryGroup>
              <CategoryName>
                Trained Skills <CategorySeparator />
              </CategoryName>
              <Skills>
                {skills
                  .filter((skill) => !!skill.isTrained)
                  .map((skill) => {
                    return (
                      <SkillWrapper key={skill.slug} trainable={false}>
                        <Skill
                          key={skill.slug}
                          skill={skill}
                          attacker={characterFighter}
                          isShowValues
                        />
                        <SkillInfo>
                          <Name>{skill.name}</Name>
                          <Unlocked>Unlocked</Unlocked>
                        </SkillInfo>
                      </SkillWrapper>
                    );
                  })}
              </Skills>
            </CategoryGroup>
            <CategoryGroup>
              <CategoryName>
                Dormant Skills <CategorySeparator />
              </CategoryName>
              <Skills>
                {skills
                  .filter((skill) => !skill.isTrained)
                  .map((skill) => {
                    return (
                      <SkillWrapper
                        key={skill.slug}
                        trainable={canTrain}
                        onClick={() => trainSkill(skill.slug)}
                      >
                        <Skill
                          key={skill.slug}
                          skill={skill}
                          attacker={characterFighter}
                          isShowValues
                        />
                        <LockedSkillInfo>
                          <Name>{skill.name}</Name>
                          <TrainCostWrapper>
                            <TrainText>Learn Skill:</TrainText>
                            <CostText>Cost:</CostText>
                            <EnhancementCost>
                              <EnhancementsIcon image={enhancementsImage} />
                              {skill.medallions}
                            </EnhancementCost>
                          </TrainCostWrapper>
                        </LockedSkillInfo>
                      </SkillWrapper>
                    );
                  })}
              </Skills>
            </CategoryGroup>
          </ListWrapper>
          {!!canTrain && (
            <ScreenButtonWrapper>
              <Tooltip
                color={COLORS.ENHANCEMENTS}
                content={
                  <>
                    <TooltipName>Reset Medallions</TooltipName>
                    <TooltipDesc>
                      Unlearn your trained skills and get back your spent
                      medallions.
                    </TooltipDesc>
                  </>
                }
              >
                <Button
                  $style="normal"
                  $size="medium"
                  $disabled={!canReset}
                  onClick={() => dispatch(buyResetSkills())}
                >
                  Reset Medallions
                  <ButtonCredits>
                    <ButtonCreditsIcon />
                    {resetCost}
                  </ButtonCredits>
                </Button>
              </Tooltip>
            </ScreenButtonWrapper>
          )}
        </SectionContentScroll>
      </Section>
    </DataWrapper>
  );
}
